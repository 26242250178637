import Head from "next/head";
import dynamic from "next/dynamic";
import AboutMe from "../components/AboutMe";
import ProjectSectionHome from "../components/ProjectSectionHome";
import { getHomepage } from "../queries/pages";
import { Layout } from "../components/Layouts";
import { HomeLayout } from "../components/Layouts";
import QuotesSection from "../components/QuotesSection";
import React from "react";
import { ContactMeHomeSection } from "../components/ContactMe";
import * as CONSTANTS from "../utils/constants";
import parse from "html-react-parser";

const MediaCarousel = dynamic(() => import("../components/MediaCarousel"), {
  ssr: false,
});

export default function Home(props) {
  const { mediaAndPress, aboutMe, projects, businessVenture, quotes, seo } =
    props;
  return (
    <>
      <Head>
        <title>Welcome to DM</title>
        <link rel='icon' href='/favicon.ico' />
        {seo && parse(seo)}
      </Head>
      <AboutMe {...aboutMe} />
      <ProjectSectionHome
        projects={projects}
        businessVenture={businessVenture}
      />
      {mediaAndPress.mediaAndPress && (
        <div className='container'>
          <MediaCarousel
            items={mediaAndPress?.mediaAndPress}
            visibleSlides={mediaAndPress.visibleSlidesMediaPress}
            aspectRatio={mediaAndPress.aspectRatioMediaPress}
            showDots={true}
            group='mediaAndPress'
            showArrows
          />
        </div>
      )}
      <div className='pb-4 pt-2'>
        <QuotesSection
          quotes={quotes?.quotes}
          background={quotes?.backgroundQuotes}
        />
      </div>
      <ContactMeHomeSection />
    </>
  );
}

export async function getStaticProps() {
  const page = await getHomepage();
  const seoDefault = CONSTANTS.SEO_DEFAULT;

  return {
    props: {
      mediaAndPress: page?.CFProjectMediaAndPress,
      aboutMe: page?.CFHOMEAboutMe,
      projects: page?.CFHomeCards?.projects,
      quotes: page?.CFProjectQuotes,
      businessVenture: page?.CFHomeCards?.businessVenture,
      seo: page?.seo?.fullHead || seoDefault,
    },
    revalidate: 10,
  };
}

Home.getLayout = function getLayout(page) {
  return (
    <Layout>
      <HomeLayout>{page}</HomeLayout>
    </Layout>
  );
};
