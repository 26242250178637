// @ts-nocheck
import React from "react";
import Section from "../Section";

import Card from "../../components/Card";
import styles from "./ContactMeHomeSection.module.css";

import classNames from "classnames";
import BtnChromaBorder from "../shared/BtnChromaBorder";

const cx = classNames.bind(styles);

export const ContactMeHomeSection = () => {
  return (
    <Section displaySection isAnimated className='relative container'>
      <Card
        className='flex flex-col bg-white text-center justify-center items-justify p-10 mb-6'
        style={{ backgroundImage: "url(./images/pattern_black.png)" }}
      >
        <div className='md:max-w-3xl my-auto mx-auto flex flex-col'>
          <h3
            className={cx(
              "text-[4.2em] md:text-9xl font-now text-bold text-center mx-auto scale-[1.05] leading-[2.7rem] mb-6",
              styles.holoText
            )}
          >
            <span className='tracking-[0.06em] md:tracking-[0.03em]'>
              LET’S WORK
            </span>
          </h3>
          <BtnChromaBorder
            href='/contact-me'
            className='w-full p-3 md:p-4 text-2xl md:text-4xl uppercase font-now font-bold block bg-white'
          >
            MESSAGE ME
          </BtnChromaBorder>
        </div>
      </Card>
    </Section>
  );
};
