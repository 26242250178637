import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";
import styles from "./ContactMeHomeSection.module.css";
import { useUIDispatchContext } from "../UIContext";
const cx = classNames.bind(styles);

const msgAnimation = {
  active: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
  inactive: {
    opacity: 0,
    y: -30,
    transition: {
      duration: 0.5,
    },
  },
};

export function ContactMeForm({ images = [] }) {
  // States htmlFor contact form fields
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [budget, setBudget] = useState("");
  const sendBtnRef = useRef();
  const recaptchaRef = React.createRef();
  const dispatch = useUIDispatchContext();

  //   Form validation state
  const [errors, setErrors] = useState({});

  //   Setting button text on form submission
  const [buttonText, setButtonText] = useState("Send");

  // Setting success or failure messages states
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);

  function cleanupForm() {
    setFullname("");
    setEmail("");
    setSubject("");
    setMessage("");
    setBudget("");
    setErrors({});
  }

  // Validation check method
  const handleValidation = () => {
    let tempErrors = {};
    let isValid = true;

    if (fullname.length <= 0) {
      tempErrors["fullname"] = true;
      isValid = false;
    }
    if (email.length <= 0 || !/^\S+@\S+\.\S+$/.test(email)) {
      tempErrors["email"] = true;
      isValid = false;
    }
    if (subject.length <= 0) {
      tempErrors["subject"] = true;
      isValid = false;
    }
    if (message.length <= 0) {
      tempErrors["message"] = true;
      isValid = false;
    }

    setErrors({ ...tempErrors });

    return isValid;
  };

  //   Handling form submit

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValidForm = handleValidation();
    if (isValidForm) {
      recaptchaRef.current.execute();
    } else {
      setShowFailureMessage(true);
    }
  };

  const onReCAPTCHAChange = async (captchaCode) => {
    if (!captchaCode) {
      return;
    }
    let isValidForm = handleValidation();
    if (isValidForm) {
      setButtonText("Sending");
      const res = await fetch("/api/sendgrid", {
        body: JSON.stringify({
          email: email,
          fullname: fullname,
          subject: subject,
          message: message,
          budget: budget,
          captcha: captchaCode,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });

      setButtonText("Sending...");

      const { error } = await res.json();

      if (error) {
        // setShowSuccessMessage(false);
        setShowFailureMessage(true);
        setButtonText("Send");
        sendBtnRef?.current?.blur();
        return;
      } else {
        // setShowSuccessMessage(true);
        setShowFailureMessage(false);
        dispatch({ type: "SET_RECIVED_IMAGE", payload: true });
        cleanupForm();
      }
      sendBtnRef?.current?.blur();
      setButtonText("Send");
    }

    recaptchaRef?.current?.reset();
  };

  return (
    <>
      <form
        className='flex flex-col bg-white overflow-hidden px-2 py-4'
        onSubmit={handleSubmit}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          size='invisible'
          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          onChange={onReCAPTCHAChange}
          className='z-10'
        />
        <h1 className='text-2xl font-bold font-now'>Message me</h1>

        <label htmlFor='fullname' className='text-gray-500 font-now mt-8'>
          Full name<span className='text-red-500'>*</span>
        </label>
        <input
          type='text'
          name='fullname'
          value={fullname}
          className={`bg-transparent border-b py-2 pl-4 focus:outline-none focus:rounded-md focus:ring-1 ring-green-500 text-gray-500 ${
            errors.fullname ? "ring-2 ring-red-500" : ""
          }`}
          onChange={(e) => setFullname(e.target.value)}
        />

        <label htmlFor='email' className='text-gray-500 font-now mt-4'>
          E-mail<span className='text-red-500'>*</span>
        </label>
        <input
          type='email'
          name='email'
          value={email}
          className={`bg-transparent border-b py-2 pl-4 focus:outline-none focus:rounded-md focus:ring-1 ring-green-500 text-gray-500 ${
            errors.email ? "ring-2 ring-red-500" : ""
          }`}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor='subject' className='text-gray-500 font-now mt-4'>
          Subject<span className='text-red-500'>*</span>
        </label>
        <input
          type='text'
          name='subject'
          value={subject}
          className={`bg-transparent border-b py-2 pl-4 focus:outline-none focus:rounded-md focus:ring-1 ring-green-500 text-gray-500 ${
            errors.subject ? "ring-2 ring-red-500" : ""
          }`}
          onChange={(e) => setSubject(e.target.value)}
        />

        <label htmlFor='budget' className='text-gray-500 font-now mt-4'>
          Budget
        </label>
        <input
          type='text'
          name='budget'
          value={budget}
          className='bg-transparent border-b py-2 pl-4 focus:outline-none focus:rounded-md focus:ring-1 ring-green-500 text-gray-500'
          onChange={(e) => setBudget(e.target.value)}
        />

        <label htmlFor='message' className='text-gray-500 font-now mt-4'>
          Message<span className='text-red-500'>*</span>
        </label>
        <textarea
          name='message'
          value={message}
          className={`bg-transparent border-b py-2 pl-4 focus:outline-none focus:rounded-md focus:ring-1 ring-green-500 text-gray-500 ${
            errors.message ? "ring-2 ring-red-500" : ""
          }`}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <div className='flex flex-row items-center justify-center z-20 mt-8 w-full'>
          <button
            ref={sendBtnRef}
            className={cx(
              "group px-10 py-2 text-gray-50 font-now rounded-md text-2xl flex flex-row items-center uppercase",
              styles.holoBtn
            )}
          >
            {buttonText}
            <FontAwesomeIcon
              icon={faPaperPlane}
              className='ml-2 group-focus:translate-x-4 group-focus:-translate-y-4 group-focus:opacity-0 transition-all duration-300 ease-in-out'
            />
          </button>
        </div>
        <div className='message w-full'>
          <div className='relative block w-full text-center font-now'>
            {/* <motion.div
              className='text-1xl absolute top-3 w-full bg-green-500 text-white px-3 py-2 z-0'
              variants={msgAnimation}
              animate={showSuccessMessage ? "active" : "inactive"}
            >
              Your message was sent 👍🏼
            </motion.div> */}

            <motion.div
              className='text-1xl absolute top-3 w-full bg-rose-600 text-white px-3 py-2 z-0'
              variants={msgAnimation}
              animate={showFailureMessage ? "active" : "inactive"}
            >
              <span className='uppercase'>Message failed to send 😨</span>
              {Object.keys(errors).length > 0 ? (
                <div className='font-regular'>
                  <span>Please review field(s): </span>

                  {Object.keys(errors).map((err, i) => (
                    <span key={err} className='text-sm text-white font-bold'>
                      {err} {i + 1 === Object.keys(errors).length ? "" : ", "}
                    </span>
                  ))}
                </div>
              ) : (
                <div className='font-regular'>
                  Please, Try again or validate CAPTCHA
                </div>
              )}
            </motion.div>
            {showFailureMessage && <div className='h-24'></div>}
          </div>
        </div>

        {/* <div
          className='absolute bg-rose-600 text-white px-3 py-2'
          onClick={() => {
            setShowFailureMessage(!showFailureMessage);
            dispatch({ type: "SET_RECIVED_IMAGE", payload: false });
          }}
        >
          fail msg
        </div>
        <div
          className='absolute ml-20 bg-green-500 text-white px-3 py-2'
          onClick={() => {
            setShowSuccessMessage(!showSuccessMessage);
            dispatch({ type: "SET_RECIVED_IMAGE", payload: true });
          }}
        >
          sent msg
        </div> */}
      </form>
    </>
  );
}
