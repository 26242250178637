import React from "react";
import Section from "../../components/Section";
import Link from "next/link";
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import styles from "./QuotesSection.module.css";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/constants";
import AnimatedBlock from "../AnimatedBlock";
import { v4 as uuidv4 } from "uuid";

const cx = classNames.bind(styles);

const QuotesSection = ({ quotes, background, ...rest }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");
  const isMoreThanOne = quotes?.length > 1;
  const textStyle =
    background === "holo" || background === "black"
      ? "text-white"
      : "text-black";

  return (
    <Section displaySection={quotes?.length > 0} isFullWidth={true} {...rest}>
      <div
        className={cx(
          `drop-shadow-card translate-z-0 overflow-hidden relative my-4 ${textStyle}`
        )}
      >
        <CarouselProvider
          naturalSlideWidth={breakpoint === "mobile" ? 2 : 5}
          naturalSlideHeight={breakpoint === "mobile" ? 2 : 0.8}
          totalSlides={quotes?.length}
          className='relative'
          interval={5000}
          isPlaying={quotes?.length > 1}
          infinite
        >
          <Slider className='z-10 py-10' key={uuidv4()}>
            {quotes?.map(({ quote, author, link, quoteLink }, i) => (
              <Slide key={uuidv4()}>
                <AnimatedBlock index={i} duration={0.9}>
                  <article className='absolute w-full h-full flex flex-col justify-center items-center text-center '>
                    <div className='relative md:max-w-5xl mx-auto'>
                      <FontAwesomeIcon
                        icon={faQuoteLeft}
                        className='text-5xl lg:text-6xl absolute -top-20 md:-top-0 z-10 -left-4 md:-left-14 opacity-60'
                      />
                      <div
                        className={cx(`font-now max-w-xs md:max-w-6xl`, {
                          "text-3xl md:text-4xl lg:text-6xl":
                            quote.length < 130,
                          "text-2xl md:text-3xl lg:text-5xl":
                            quote.length > 130 && quote.length < 240,
                          "text-2xl md:text-2xl lg:text-4xl":
                            quote.length > 240 && quote.length < 350,
                          "text-base md:text-[1.2rem] lg:text-3xl":
                            quote.length > 350,
                        })}
                      >
                        {quoteLink ? (
                          <Link href={quoteLink} scroll={false}>
                            {quote}
                          </Link>
                        ) : (
                          quote
                        )}
                      </div>
                      <a
                        target='_blank'
                        href={link}
                        rel='noreferrer'
                        className='text-2xl md:text-3xl mt-8 italic block'
                      >
                        {author}
                      </a>
                      <FontAwesomeIcon
                        icon={faQuoteRight}
                        className='text-5xl md:text-6xl absolute md:-top-0 -bottom-10 z-10 -right-4 md:-right-14 opacity-60'
                      />
                    </div>
                  </article>
                </AnimatedBlock>
              </Slide>
            ))}
          </Slider>
          {breakpoint === "mobile" && isMoreThanOne ? (
            <DotGroup
              className={cx(styles.dotsCarousel, "z-40", {
                [styles.dotsCarouselDark]: background === "white",
              })}
            />
          ) : (
            isMoreThanOne && (
              <>
                <ButtonBack className='disabled:opacity-20 block'>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className='text-9xl absolute top-[30%] md:top-[35%] z-10 left-[8%]'
                  />
                </ButtonBack>
                <ButtonNext className='disabled:opacity-20 block'>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className='text-9xl absolute top-[30%] md:top-[35%] z-10 right-[8%]'
                  />
                </ButtonNext>
              </>
            )
          )}
        </CarouselProvider>
        <div
          className={cx(
            `
          z-0 absolute md:animate-bg-holo top-0
          w-[300%] h-[550px]
          !bg-${background}`,
            {
              [styles.holo]: background === "holo",
              ["bg-black"]: background === "black",
            }
          )}
        />
      </div>
    </Section>
  );
};
export default QuotesSection;
