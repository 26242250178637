import classNames from "classnames";
import React from "react";
import Card from "../Card";
import Container from "../Container";
// import CustomImage from "../CustomImage";
import styles from "./AboutMe.module.css";
import RenderContent from "../HTMLContent";
import Link from "next/link";
import Image from "next/image";

const AboutMe = (props) => {
  const { imageHomeDesktop, imageHomeMobile, homesText, buttonLink } = props;
  return (
    <Container className='container px-4' component='section' isAnimated>
      <Card
        className={classNames(
          "px-8 py-14 lg:py-32 lg:px-24",
          styles.AboutMeGradient
        )}
        style={{
          background: `url(${imageHomeDesktop?.sourceUrl}), var(--holo-bg)`,
          backgroundSize: "cover",
        }}
      >
        <div className='w-full shrink-auto lg:shrink-0 lg:w-7/12 flex flex-col '>
          <Image
            className='rounded-full h-22 w-22 mx-auto overflow-hidden mb-8 md:hidden'
            src={imageHomeMobile?.sourceUrl}
            alt={imageHomeMobile?.altText}
            width='384'
            height='512'
          />
          <h1 className='text-5xl md:text-6xl font-now font-black uppercase mb-4'>
            About Me
          </h1>
          <RenderContent html={homesText} className='font-lato text-lg' />
          <button className='rounded-none text-3xl border-black font-black font-now uppercase py-1 px-3 border-2 mt-8 md:mt-5 md:mr-auto md:ml-0 ml-auto mr-auto hover:bg-black hover:text-white'>
            <Link href={buttonLink?.url} scroll={false}>
              {buttonLink.title}
            </Link>
          </button>
        </div>
      </Card>
    </Container>
  );
};

export default AboutMe;
